import { FILTERS_EQUAL } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "after-close": _vm.afterModalClose, "destroy-on-close": "", "title": "Edit Item Classification" }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.cancel } }, [_vm._v("Cancel")]), _c("a-button", { staticClass: "ant-btn ant-btn-danger ant-btn-background-ghost", on: { "click": _vm.onToggleActiveClick } }, [_vm._v("Deactivate")]), _c("a-button", { attrs: { "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.onSave);
      } } }, [_vm._v(" Save ")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("div", { staticClass: "edit-item-classification-modal__content" }, [_c("a-row", { staticClass: "pl-8 pr-8 pb-5" }, [_c("select-input", { key: "countryID", attrs: { "data-source": _vm.arrCountry, "source": "id", "source-label": "name", "rules": "required", "label": "Country", "span": 24, "disabled": true, "value": _vm.entity.countryID, "placeholder": "Select Country", "on-select-change": _vm.onCountryChange } })], 1), _c("a-row", { staticClass: "pl-8 pr-8 pb-5" }, [_c("select-input", { key: "itemCode", attrs: { "advanced-filters": _vm.itemCodeFilter, "source": "itemCode", "source-label": _vm.generateLabel, "rules": "required", "label": "Item No.", "lazy": "", "value": _vm.entity.itemCode, "disabled": true, "fetch-options-on-filter": true, "reference": "shipment-closure.item-data", "span": 24, "placeholder": "Select Item No.", "on-select-change": _vm.onItemCodeChange } })], 1), _c("a-row", { staticClass: "pl-8 pr-8" }, [_c("text-input", { key: "itemName", attrs: { "form-item": "", "rules": "required|max:123", "label": "Item", "max-length": 123, "span": 24, "disabled": true, "placeholder": "Item", "value": _vm.entity.itemName } })], 1), _c("a-row", { staticClass: "pl-8 pr-8 pb-5" }, [_c("select-input", { key: "categoryID", attrs: { "data-source": _vm.listCategory, "source": "id", "source-label": "categoryName", "rules": "required", "label": "Category", "show-search": "", "all-in-value": "", "span": 24, "value": _vm.entity.categoryID, "placeholder": "Select Category", "on-select-change": _vm.onCategoryChange } })], 1), _c("a-row", { staticClass: "pl-8 pr-8" }, [_c("text-input", { key: "portfolioName", attrs: { "form-item": "", "rules": "required|max:123", "label": "Portfolio", "max-length": 123, "span": 24, "disabled": true, "placeholder": "Portfolio", "value": _vm.entity.portfolioName } })], 1)], 1)])];
  } }]) });
};
var staticRenderFns$1 = [];
render$1._withStripped = true;
var EditItemClassificationData_vue_vue_type_style_index_0_lang = "";
const __vue2_script$1 = {
  name: "EditItemClassificationData",
  inject: ["crud", "apiUrl", "resourceName"],
  data() {
    return {
      itemName: "",
      portfolioName: "",
      selectedCountryID: 1,
      arrCountry: [],
      listCategory: [],
      objItem: [],
      objCategory: [],
      isFetching: false,
      visible: true,
      selectCategoryID: 0
    };
  },
  computed: {
    itemCodeFilter() {
      if (!this.selectedCountryID)
        return null;
      return [
        [
          "country",
          { operator: FILTERS_EQUAL, value: this.selectedCountryID }
        ]
      ];
    },
    entity() {
      if (this.entityId)
        return this.crud.getEntity(this.entityId);
      return this.crud.getEntity();
    }
  },
  async created() {
    let datacountry = await this.axios.get(`${this.apiUrl}/shipment-closure/country-data`);
    this.arrCountry = datacountry.data.results;
    const { data } = await this.axios.get(`${this.apiUrl}/shipment-closure/category-data?fe=((IsInactive Eq 'false'))`);
    this.listCategory = data.results;
  },
  methods: {
    async onCountryChange(value) {
      this.selectedCountryID = value;
      this.isFetching = false;
    },
    async onCategoryChange(value) {
      this.entity.portfolioName = value.portfolioName;
      this.selectCategoryID = value.id;
    },
    async onItemCodeChange(value) {
      this.itemName = value.itemCodeDesc;
    },
    generateLabel(itemData) {
      return itemData.itemCodeDesc ? `${itemData.itemCode} ${itemData.itemCodeDesc}` : `${itemData.itemCode}`;
    },
    afterModalClose() {
      this.$router.push(`/shipment-closure/item-classification-data/`);
    },
    cancel() {
      this.visible = false;
      this.$router.push(`/shipment-closure/item-classification-data/`);
    },
    async onSave() {
      await this.crud.submitPartialEntity({
        resourceName: this.resourceName,
        id: this.entity.id,
        data: [
          {
            op: "replace",
            path: "/categoryID",
            value: this.selectCategoryID
          },
          {
            op: "replace",
            path: "/countryID",
            value: this.entity.countryID
          },
          {
            op: "replace",
            path: "/itemCode",
            value: this.entity.itemCode
          }
        ]
      });
      await this.crud.clearEntity("editForm");
      this.visible = false;
      this.crud.fetchList();
    },
    onToggleActiveClick(record, customTitle = null, type = null) {
      let icon, okType, title;
      if (record.isInactive) {
        title = "Do you want to Activate this item?";
        icon = "eye";
        okType = "primary";
      } else {
        title = customTitle ? customTitle : "Do you want to Deactivate this item?";
        icon = type ? type : "eye-invisible";
        okType = "danger";
      }
      this.$confirm({
        class: "list__modal-confirm",
        icon,
        title,
        cancelText: "No",
        okText: "Yes",
        okType,
        onOk: this.toggleActive
      });
    },
    async toggleActive() {
      await this.crud.submitPartialEntity({
        resourceName: this.resourceName,
        id: this.entity.id,
        data: [
          {
            op: "replace",
            path: "/IsInactive",
            value: !this.entity.isInactive
          }
        ]
      });
      this.visible = false;
      this.crud.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
__component__$1.options.__file = "src/views/ItemClassificationData/EditItemClassificationData/EditItemClassificationData.vue";
var EditItemClassificationData = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-item-classification-data" }, [_c("resource", { attrs: { "name": "shipment-closure.item-classification-data", "api-url": _vm.apiUrl, "redirect-route": "/shipment-closure/item-classification-data" } }, [_c("edit-item-classification-data")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditItemClassificationData
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
__component__.options.__file = "src/views/ItemClassificationData/EditItemClassificationData/index.vue";
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
